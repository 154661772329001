import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSets from 'redux/selectors/cellSets/getCellSets';
import { unionByCellClass } from 'utils/cellSetOperations';
var getFilteredCellIds = function getFilteredCellIds() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (state) {
    var _options$asSet = options.asSet,
      asSet = _options$asSet === void 0 ? false : _options$asSet,
      _options$sorted = options.sorted,
      sorted = _options$sorted === void 0 ? true : _options$sorted;
    if (asSet && sorted) {
      throw new Error('Cannot return a set as sorted');
    }
    if (!state || !state.accessible) {
      return [];
    }
    var filteredCellIdsSet = unionByCellClass('louvain', state.hierarchy, state.properties);
    if (asSet) {
      return filteredCellIdsSet;
    }
    var filteredCellIds = Array.from(filteredCellIdsSet);
    if (!sorted) {
      return filteredCellIds;
    }
    return filteredCellIds.sort(function (a, b) {
      return a - b;
    });
  };
};
export default createMemoizedSelector(getFilteredCellIds, {
  inputSelectors: getCellSets(),
  // Output can be relatively large, so better not to
  // load this onto memory unless a component needs it
  maxCachedKeys: 0
});