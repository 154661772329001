import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _sumBy from "lodash/sumBy";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.filter.js";
import * as setOperations from 'utils/setOperations';

/**
 *
 * @param {*} cellClassKey The key of the cell class we want to unite by, e.g.: 'louvain'
 * @param {*} hierarchy
 * @param {*} properties
 * @returns A Set of all cell ids of the cellClassKey
 */
var unionByCellClass = function unionByCellClass(cellClassKey, hierarchy, properties) {
  var cellSetKeys = hierarchy.find(function (_ref) {
    var key = _ref.key;
    return key === cellClassKey;
  }).children.map(function (_ref2) {
    var key = _ref2.key;
    return key;
  });
  return union(cellSetKeys, properties);
};
var union = function union(listOfSets, properties) {
  if (!listOfSets) {
    return new Set();
  }
  var sets = listOfSets.map(function (key) {
    var _properties$key;
    return ((_properties$key = properties[key]) === null || _properties$key === void 0 ? void 0 : _properties$key.cellIds) || [];
  });
  // flatten and transform list of Sets to list of lists
  var unionSet = new Set(sets.flatMap(function (set) {
    return _toConsumableArray(set);
  }));
  return unionSet;
};
var intersection = function intersection(listOfSets, properties) {
  if (!listOfSets) {
    return new Set();
  }
  var sets = listOfSets.map(function (key) {
    var _properties$key2;
    return ((_properties$key2 = properties[key]) === null || _properties$key2 === void 0 ? void 0 : _properties$key2.cellIds) || null;
  }).filter(function (set) {
    return set && set.size > 0;
  });
  if (sets.length === 0) {
    return new Set();
  }
  var intersectionSet = sets.reduce(function (acc, curr) {
    return new Set(_toConsumableArray(acc).filter(function (x) {
      return curr.has(x);
    }));
  });
  return intersectionSet;
};
var complement = function complement(listOfSets, properties, hierarchy) {
  if (!listOfSets) {
    return new Set();
  }
  // get the ids of all selected cells
  var selectedCells = listOfSets.map(function (key) {
    var _properties$key3;
    return ((_properties$key3 = properties[key]) === null || _properties$key3 === void 0 ? void 0 : _properties$key3.cellIds) || null;
  }).filter(function (set) {
    return set && set.size > 0;
  }).reduce(function (acc, curr) {
    return new Set([].concat(_toConsumableArray(acc), _toConsumableArray(curr)));
  });
  var complementSet = getFilteredCells({
    hierarchy: hierarchy,
    properties: properties
  });

  // remove all cells that are selected
  if (selectedCells.size > 0) {
    selectedCells.forEach(function (x) {
      complementSet.delete(x);
    });
  }

  // return the rest of the cells that are in the dataset and were not selected
  return complementSet;
};
var getFilteredCells = function getFilteredCells(cellSets) {
  var louvainClusters = cellSets.hierarchy.find(function (_ref3) {
    var key = _ref3.key;
    return key === 'louvain';
  }).children;
  var louvainClustersCellIds = louvainClusters.map(function (_ref4) {
    var key = _ref4.key;
    return cellSets.properties[key].cellIds;
  });
  var filteredInCellIds = louvainClustersCellIds.reduce(function (filteredInCellIdsAcum, cellIds) {
    return setOperations.union(filteredInCellIdsAcum, cellIds);
  }, new Set());
  return filteredInCellIds;
};
var withoutFilteredOutCells = function withoutFilteredOutCells(cellSets, originalCellIds) {
  var filteredInCellIds = getFilteredCells(cellSets);
  return setOperations.intersection(filteredInCellIds, originalCellIds);
};
var allAreClusters = function allAreClusters(cellSetsArr) {
  return cellSetsArr.every(function (_ref5) {
    var parentNodeKey = _ref5.parentNodeKey;
    return parentNodeKey === 'louvain';
  });
};
var countCells = function countCells(cellSetKeys, filteredCellIds, properties) {
  var cellSetsArr = cellSetKeys.map(function (key) {
    return properties[key];
  }).filter(function (_ref6) {
    var rootNode = _ref6.rootNode;
    return !rootNode;
  });

  // If all the cell sets are louvain (always disjoint and filtered), then we don't need to
  // do an expensive count, just return the sum of each individual set's size
  if (allAreClusters(cellSetsArr)) {
    return _sumBy(cellSetsArr, 'cellIds.size');
  }
  var selectedCells = new Set();
  cellSetsArr.forEach(function (cellSet) {
    cellSet.cellIds.forEach(function (cellId) {
      if (filteredCellIds.has(cellId)) {
        selectedCells.add(cellId);
      }
    });
  });
  return selectedCells.size;
};
export { union, intersection, complement, unionByCellClass, getFilteredCells, withoutFilteredOutCells, countCells };