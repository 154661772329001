var CELL_SETS = 'cellSets';

/**
 * Updates the cells sets shown to the user with the hierarchy fetched
 * from the API.
 */
var CELL_SETS_LOADED = "".concat(CELL_SETS, "/loaded");

/**
 * Signals to the UI that new cell set data is about to be loaded in.
 */
var CELL_SETS_LOADING = "".concat(CELL_SETS, "/loading");

/**
 * Creates a new cell set.
 */
var CELL_SETS_CREATE = "".concat(CELL_SETS, "/create");

/**
 * Updates a single cell set's properties.
 */
var CELL_SETS_UPDATE_PROPERTY = "".concat(CELL_SETS, "/updateProperty");

/**
 * Cell sets were reordered
 */
var CELL_SETS_REORDER = "".concat(CELL_SETS, "/reorder");

/**
 * Deletes a cell set.
 */
var CELL_SETS_DELETE = "".concat(CELL_SETS, "/delete");

/**
 * Deletes a cell class.
 */
var CELL_CLASS_DELETE = "".concat(CELL_SETS, "/deleteClass");

/**
 * Saves all cell sets currently loaded.
 */
var CELL_SETS_SAVE = "".concat(CELL_SETS, "/save");

/**
 * Hide a cell set from visualizations.
 */
var CELL_SETS_HIDE = "".concat(CELL_SETS, "/hide");

/**
 * Unhide [remove a previous hidden state] from a cell set from visualizations.
 */
var CELL_SETS_UNHIDE = "".concat(CELL_SETS, "/unhide");

/**
 * Unhide all hidden cell sets.
 */
var CELL_SETS_UNHIDE_ALL = "".concat(CELL_SETS, "/unhideAll");

// /**
//  * Cell sets clustering being recalculated.
//  */
var CELL_SETS_CLUSTERING_UPDATING = "".concat(CELL_SETS, "/clusteringUpdating");

// /**
//  * Cell sets clustering being recalculated.
//  */
var CELL_SETS_CLUSTERING_UPDATED = "".concat(CELL_SETS, "/clusteringUpdated");

/**
 * Creates an error condition in the cell set tool.
 */
var CELL_SETS_ERROR = "".concat(CELL_SETS, "/error");
export { CELL_SETS_LOADING, CELL_SETS_LOADED, CELL_SETS_CREATE, CELL_SETS_UPDATE_PROPERTY, CELL_SETS_REORDER, CELL_SETS_DELETE, CELL_CLASS_DELETE, CELL_SETS_SAVE, CELL_SETS_HIDE, CELL_SETS_UNHIDE, CELL_SETS_UNHIDE_ALL, CELL_SETS_CLUSTERING_UPDATING, CELL_SETS_CLUSTERING_UPDATED, CELL_SETS_ERROR };