import _isNil from "lodash/isNil";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var getAnalysisTool = function getAnalysisTool() {
  return function (state) {
    var _state$processing;
    var analysisTool = state === null || state === void 0 || (_state$processing = state.processing) === null || _state$processing === void 0 || (_state$processing = _state$processing.dataIntegration) === null || _state$processing === void 0 ? void 0 : _state$processing.analysisTool;
    if (_isNil(analysisTool)) {
      return null;
    }
    return analysisTool;
  };
};
export default createMemoizedSelector(getAnalysisTool);