import _isEqual from "lodash/isEqual";
import _isNil from "lodash/isNil";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getAnalysisTool from 'redux/selectors/experimentSettings/getAnalysisTool';
import { analysisTools } from 'utils/constants';
var getIsScanpy = function getIsScanpy() {
  return function (analysisTool) {
    if (_isNil(analysisTool)) return null;
    return _isEqual(analysisTool, analysisTools.SCANPY);
  };
};
export default createMemoizedSelector(getIsScanpy, {
  inputSelectors: getAnalysisTool()
});