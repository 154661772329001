import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
var difference = function difference(filteredSet, filteringSet) {
  var result = new Set(_toConsumableArray(filteredSet).filter(function (x) {
    return !filteringSet.has(x);
  }));
  return result;
};
var union = function union(set1, set2) {
  var resultSet = new Set();
  set1.forEach(function (elem) {
    return resultSet.add(elem);
  });
  set2.forEach(function (elem) {
    return resultSet.add(elem);
  });
  return resultSet;
};

// Uses includes or has depending on if set2 is an Array or a Set
var contains = function contains(container, element) {
  return Array.isArray(container) ? container.includes(element) : container.has(element);
};
var intersection = function intersection(set1, set2) {
  var resultSet = new Set();
  set1.forEach(function (elem) {
    if (contains(set2, elem)) {
      resultSet.add(elem);
    }
  });
  return resultSet;
};
export { difference, union, contains, intersection };