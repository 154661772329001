import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var initialState = {
  properties: {},
  hierarchy: [],
  initialLoadPending: true,
  loading: false,
  error: false,
  updatingClustering: false,
  hidden: new Set()
};
export default initialState;