import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "antd/lib/divider/style";
import _Divider from "antd/lib/divider";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import "antd/lib/menu/style";
import _Menu from "antd/lib/menu";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
import "antd/lib/layout/style";
import _Layout from "antd/lib/layout";
import _isEqual from "lodash/isEqual";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5ToTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import { BuildOutlined, FundViewOutlined, BarChartOutlined, NodeExpandOutlined, FileDoneOutlined, DotChartOutlined } from '@ant-design/icons';
import pipelineErrorUserMessages from 'utils/pipelineErrorUserMessages';
import TermsOfUseIntercept from 'components/data-management/TermsOfUseIntercept';
import BrowserAlert from 'components/BrowserAlert';
import PreloadContent from 'components/PreloadContent';
import GEM2SLoadingScreen from 'components/GEM2SLoadingScreen';
import PipelineRedirectToDataProcessing from 'components/PipelineRedirectToDataProcessing';
import { getBackendStatus } from 'redux/selectors';
import { loadUser } from 'redux/actions/user';
import { loadBackendStatus } from 'redux/actions/backendStatus';
import { isBrowser } from 'utils/deploymentInfo';
import { modules, brandColors } from 'utils/constants';
import { useAppRouter } from 'utils/AppRouteProvider';
import experimentUpdatesHandler from 'utils/experimentUpdatesHandler';
import integrationTestConstants from 'utils/integrationTestConstants';
import pipelineStatusValues from 'utils/pipelineStatusValues';
import { DndProvider } from 'react-dnd';
import { loadSamples } from 'redux/actions/samples';
import calculatePipelinesRerunStatus from 'utils/data-management/calculatePipelinesRerunStatus';
import termsOfUseNotAccepted from 'utils/termsOfUseNotAccepted';
import CookieBanner from './CookieBanner';
import FeedbackButton from './sider/FeedbackButton';
import ReferralButton from './sider/ReferralButton';
import UserButton from './sider/UserButton';
var Sider = _Layout.Sider;
var Text = _Typography.Text;
var Item = _Menu.Item,
  SubMenu = _Menu.SubMenu,
  ItemGroup = _Menu.ItemGroup;
var checkEveryIsValue = function checkEveryIsValue(arr, value) {
  return arr.every(function (item) {
    return item === value;
  });
};
var backendErrors = [pipelineStatusValues.FAILED, pipelineStatusValues.TIMED_OUT, pipelineStatusValues.ABORTED];
var ContentWrapper = function ContentWrapper(props) {
  var _useSelector3, _backendStatus$pipeli, _backendStatus$gem2s, _backendStatus$gem2s2, _backendStatus$seurat, _backendStatus$seurat2;
  var dispatch = useDispatch();
  var _useState = useState(false),
    collapsed = _useState[0],
    setCollapsed = _useState[1];
  var routeExperimentId = props.routeExperimentId,
    routeAnalysisId = props.routeAnalysisId,
    experimentData = props.experimentData,
    children = props.children;
  var _useAppRouter = useAppRouter(),
    navigateTo = _useAppRouter.navigateTo,
    currentModule = _useAppRouter.currentModule;
  var currentExperimentIdRef = useRef(routeExperimentId);
  var currentAnalysisIdRef = useRef(routeAnalysisId);
  var selectedExperimentID = useSelector(function (state) {
    var _state$experiments;
    return state === null || state === void 0 || (_state$experiments = state.experiments) === null || _state$experiments === void 0 || (_state$experiments = _state$experiments.meta) === null || _state$experiments === void 0 ? void 0 : _state$experiments.activeExperimentId;
  });
  var selectedAnalysisID = useSelector(function (state) {
    var _state$secondaryAnaly;
    return state === null || state === void 0 || (_state$secondaryAnaly = state.secondaryAnalyses) === null || _state$secondaryAnaly === void 0 || (_state$secondaryAnaly = _state$secondaryAnaly.meta) === null || _state$secondaryAnaly === void 0 ? void 0 : _state$secondaryAnaly.activeSecondaryAnalysisId;
  });
  var domainName = useSelector(function (state) {
    return state.networkResources.domainName;
  });
  var user = useSelector(function (state) {
    return state.user.current;
  });
  var samples = useSelector(function (state) {
    return state.samples;
  });
  useEffect(function () {
    // selectedExperimentID holds the value in redux of the selected experiment
    // after loading a page it is determined whether to use that ID or the ID in the route URL
    // i.e. when we are in data management there is not exp ID in the URL so we get it from redux
    if (!selectedExperimentID && !routeExperimentId) return;
    if (currentModule === modules.DATA_MANAGEMENT) {
      currentExperimentIdRef.current = selectedExperimentID;
      return;
    }
    if (currentExperimentIdRef.current === routeExperimentId) return;
    currentExperimentIdRef.current = routeExperimentId;
  }, [currentModule, selectedExperimentID, routeExperimentId]);
  useEffect(function () {
    if (currentModule === modules.SECONDARY_ANALYSIS) {
      currentAnalysisIdRef.current = selectedAnalysisID;
    } else if (currentModule === modules.SECONDARY_ANALYSIS_OUTPUT) {
      currentAnalysisIdRef.current = routeAnalysisId;
    }
  }, [currentModule, selectedAnalysisID]);
  var currentExperimentId = currentExperimentIdRef.current;
  var experiment = useSelector(function (state) {
    return state === null || state === void 0 ? void 0 : state.experiments[currentExperimentId];
  });
  var firstSampleId = Object.keys(samples).find(function (id) {
    return samples[id].experimentId === currentExperimentId;
  });
  var selectedTechnology = firstSampleId ? samples[firstSampleId].type : false;
  var experimentName = (experimentData === null || experimentData === void 0 ? void 0 : experimentData.experimentName) || (experiment === null || experiment === void 0 ? void 0 : experiment.name);
  var secondaryAnalysisName = useSelector(function (state) {
    var _state$secondaryAnaly2;
    return state === null || state === void 0 || (_state$secondaryAnaly2 = state.secondaryAnalyses) === null || _state$secondaryAnaly2 === void 0 || (_state$secondaryAnaly2 = _state$secondaryAnaly2[currentAnalysisIdRef.current]) === null || _state$secondaryAnaly2 === void 0 ? void 0 : _state$secondaryAnaly2.name;
  }, _isEqual);
  var _useSelector = useSelector(getBackendStatus(currentExperimentId)),
    backendLoading = _useSelector.loading,
    backendError = _useSelector.error,
    backendStatus = _useSelector.status;
  var _useSelector2 = useSelector(function (state) {
      return state.secondaryAnalyses.meta;
    }),
    activeSecondaryAnalysisId = _useSelector2.activeSecondaryAnalysisId;
  var _ref = (_useSelector3 = useSelector(function (state) {
      var _state$secondaryAnaly3;
      return (_state$secondaryAnaly3 = state.secondaryAnalyses[activeSecondaryAnalysisId]) === null || _state$secondaryAnaly3 === void 0 ? void 0 : _state$secondaryAnaly3.status;
    })) !== null && _useSelector3 !== void 0 ? _useSelector3 : {},
    analysisStatus = _ref.current;
  var qcStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$pipeli = backendStatus.pipeline) === null || _backendStatus$pipeli === void 0 ? void 0 : _backendStatus$pipeli.status;
  var qcRunning = qcStatusKey === 'RUNNING';
  var qcRunningError = backendErrors.includes(qcStatusKey);
  var gem2sStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$gem2s = backendStatus.gem2s) === null || _backendStatus$gem2s === void 0 ? void 0 : _backendStatus$gem2s.status;
  var gem2sRunning = gem2sStatusKey === 'RUNNING';
  var gem2sRunningError = backendErrors.includes(gem2sStatusKey);
  var completedGem2sSteps = backendStatus === null || backendStatus === void 0 || (_backendStatus$gem2s2 = backendStatus.gem2s) === null || _backendStatus$gem2s2 === void 0 ? void 0 : _backendStatus$gem2s2.completedSteps;
  var seuratStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$seurat = backendStatus.seurat) === null || _backendStatus$seurat === void 0 ? void 0 : _backendStatus$seurat.status;
  var isSeurat = seuratStatusKey && selectedTechnology === 'seurat';
  var _useState2 = useState(null),
    pipelinesRerunStatus = _useState2[0],
    setPipelinesRerunStatus = _useState2[1];
  var seuratRunning = seuratStatusKey === 'RUNNING' && isSeurat;
  var seuratRunningError = backendErrors.includes(seuratStatusKey) && isSeurat;
  var completedSeuratSteps = backendStatus === null || backendStatus === void 0 || (_backendStatus$seurat2 = backendStatus.seurat) === null || _backendStatus$seurat2 === void 0 ? void 0 : _backendStatus$seurat2.completedSteps;
  var seuratComplete = seuratStatusKey === pipelineStatusValues.SUCCEEDED && isSeurat;
  var waitingForQcToLaunch = gem2sStatusKey === pipelineStatusValues.SUCCEEDED && qcStatusKey === pipelineStatusValues.NOT_CREATED;
  // This is used to prevent a race condition where the page would start loading immediately
  // when the backend status was previously loaded. In that case, `backendLoading` is `false`
  // and would be set to true only in the `loadBackendStatus` action, the time between the
  // two events would allow pages to load.
  var _useState3 = useState(false),
    backendStatusRequested = _useState3[0],
    setBackendStatusRequested = _useState3[1];
  useEffect(function () {
    var _experimentData$sampl;
    if (!currentExperimentId) return;
    if (!backendLoading) dispatch(loadBackendStatus(currentExperimentId));
    // need to load the samples to get the selected technology of the experiment
    // in the future, selected technology can be moved to under .experiments
    if (!samples[experimentData === null || experimentData === void 0 || (_experimentData$sampl = experimentData.sampleIds) === null || _experimentData$sampl === void 0 ? void 0 : _experimentData$sampl[0]]) dispatch(loadSamples(routeExperimentId));
    if (isBrowser) {
      import('utils/socketConnection').then(function (_ref2) {
        var connectionPromise = _ref2.default;
        return connectionPromise;
      }).then(function (io) {
        var cb = experimentUpdatesHandler(dispatch);

        // Unload all previous socket.io hooks that may have been created for a different
        // experiment.
        io.off();
        io.on("ExperimentUpdates-".concat(currentExperimentId), function (update) {
          return cb(currentExperimentId, update);
        });
      });
    }
  }, [routeExperimentId]);
  useEffect(function () {
    if (backendStatusRequested) {
      return;
    }
    setBackendStatusRequested(true);
  }, [backendLoading]);
  useEffect(function () {
    var setupPipeline = isSeurat ? 'seurat' : 'gem2s';
    var _ref3 = backendStatus !== null && backendStatus !== void 0 ? backendStatus : {},
      qcBackendStatus = _ref3.pipeline,
      setupBackendStatus = _ref3[setupPipeline];
    if (!experiment || !setupBackendStatus) return;

    // The value of backend status is null for new experiments that have never run

    setPipelinesRerunStatus(calculatePipelinesRerunStatus(setupBackendStatus, qcBackendStatus, experiment, isSeurat));
  }, [backendStatus, experiment, samples]);
  useEffect(function () {
    dispatch(loadUser());
  }, []);
  if (!user) return __jsx(React.Fragment, null);
  var getStatusObject = function getStatusObject(type, status) {
    var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var completedSteps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    return _objectSpread(_objectSpread({
      type: type,
      status: status
    }, message && {
      message: message
    }), completedSteps && {
      completedSteps: completedSteps
    });
  };
  var gem2sNotCreated = checkEveryIsValue([gem2sStatusKey, seuratStatusKey], pipelineStatusValues.NOT_CREATED);
  var getSeuratStatus = function getSeuratStatus() {
    if (seuratRunningError) {
      var _backendStatus$seurat3;
      var _errorMessage = pipelineErrorUserMessages[backendStatus === null || backendStatus === void 0 || (_backendStatus$seurat3 = backendStatus.seurat) === null || _backendStatus$seurat3 === void 0 || (_backendStatus$seurat3 = _backendStatus$seurat3.error) === null || _backendStatus$seurat3 === void 0 ? void 0 : _backendStatus$seurat3.error];
      return getStatusObject('seurat', 'error', _errorMessage);
    }
    if (seuratRunning) {
      return getStatusObject('seurat', 'running', null, completedSeuratSteps);
    }
    return null;
  };
  var getGem2sStatus = function getGem2sStatus() {
    if (gem2sRunningError) return getStatusObject('gem2s', 'error');
    if (gem2sRunning && experiment !== null && experiment !== void 0 && experiment.isSubsetted) {
      return getStatusObject('gem2s', 'subsetting', null, completedGem2sSteps);
    }
    if (gem2sRunning || waitingForQcToLaunch) {
      return getStatusObject('gem2s', 'running', null, completedGem2sSteps);
    }
    if (gem2sNotCreated) return getStatusObject('gem2s', 'toBeRun');
    return null;
  };
  var getQcStatus = function getQcStatus() {
    if (currentModule !== modules.DATA_PROCESSING) {
      if (qcRunningError) return getStatusObject('qc', 'error');
      if (qcRunning) return getStatusObject('qc', 'running');
      if (qcStatusKey === pipelineStatusValues.NOT_CREATED) {
        return getStatusObject('qc', 'toBeRun');
      }
    }
    return null;
  };
  var getCurrentStatusScreen = function getCurrentStatusScreen() {
    if (isSeurat) {
      return getSeuratStatus();
    }
    return getGem2sStatus() || getQcStatus();
  };
  var currentStatusScreen = getCurrentStatusScreen();
  var BigLogo = function BigLogo() {
    return __jsx("div", {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: "linear-gradient(315deg, ".concat(brandColors.DARK_LILAC, " 0%, ").concat(brandColors.INDIGO, " 30%, ").concat(brandColors.DARK_INDIGO, " 100%)"),
        paddingTop: '10px',
        paddingBottom: '10px',
        pointerEvents: 'none',
        userSelect: 'none'
      }
    }, __jsx("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: 200,
      height: 50
    }, __jsx("defs", {
      id: "svg_document_defs"
    }, __jsx("style", {
      id: "M Plus 2_Google_Webfont_import"
    }, "@import url(https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100..900&display=swap);")), __jsx("g", {
      transform: "translate(20, 25)"
    }, __jsx("image", {
      href: "/Parse_icon_white.png",
      x: "-5",
      y: "-20",
      width: "18%"
    }), __jsx("text", {
      style: {
        outlineStyle: 'none'
      },
      fontWeight: "900",
      textRendering: "geometricPrecision",
      fontFamily: "M Plus 2",
      fill: "#F0F2F5",
      fontSize: "22.00px",
      textAnchor: "start",
      dominantBaseline: "middle",
      x: "35"
    }, "Trailmaker"))));
  };
  var SmallLogo = function SmallLogo() {
    return __jsx("div", {
      style: {
        background: "linear-gradient(315deg, ".concat(brandColors.DARK_LILAC, " 0%, ").concat(brandColors.INDIGO, " 30%, ").concat(brandColors.DARK_INDIGO, " 100%)"),
        paddingTop: '8px',
        paddingBottom: '8px',
        pointerEvents: 'none',
        userSelect: 'none'
      }
    }, __jsx("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: 100,
      height: 30
    }, __jsx("defs", {
      id: "svg_document_defs"
    }, __jsx("style", {
      id: "M Plus 2_Google_Webfont_import"
    }, "@import url(https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100..900&display=swap);")), __jsx("g", null, __jsx("image", {
      href: "/Parse_icon_white.png",
      x: "20",
      y: "0",
      width: "35%"
    }))));
  };
  var menuLinks = [{
    module: modules.SECONDARY_ANALYSIS,
    icon: __jsx(NodeExpandOutlined, null),
    name: 'Pipeline',
    selectedProjectText: secondaryAnalysisName || 'No run selected',
    isDisabled: false,
    items: [{
      module: modules.SECONDARY_ANALYSIS_OUTPUT,
      name: 'Pipeline Output',
      icon: __jsx(FileDoneOutlined, null),
      get isDisabled() {
        return !activeSecondaryAnalysisId || analysisStatus && analysisStatus === 'not_created' && currentModule === modules.SECONDARY_ANALYSIS;
      }
    }]
  }, {
    module: modules.DATA_MANAGEMENT,
    icon: __jsx(DotChartOutlined, null),
    name: 'Insights',
    selectedProjectText: experimentName || 'No project selected',
    get isDisabled() {
      return getTertiaryModuleDisabled(this.module);
    },
    items: [{
      module: modules.DATA_PROCESSING,
      icon: __jsx(BuildOutlined, null),
      name: 'Data Processing',
      get isDisabled() {
        return getTertiaryModuleDisabled(this.module);
      }
    }, {
      module: modules.DATA_EXPLORATION,
      icon: __jsx(FundViewOutlined, null),
      name: 'Data Exploration',
      get isDisabled() {
        return getTertiaryModuleDisabled(this.module);
      }
    }, {
      module: modules.PLOTS_AND_TABLES,
      icon: __jsx(BarChartOutlined, null),
      name: 'Plots and Tables',
      get isDisabled() {
        return getTertiaryModuleDisabled(this.module);
      }
    }]
  }];
  var renderContent = function renderContent() {
    if (routeExperimentId) {
      if (backendLoading || !backendStatusRequested) {
        return __jsx(PreloadContent, null);
      }
      if (currentStatusScreen && currentStatusScreen.type !== 'qc') {
        return __jsx(GEM2SLoadingScreen, {
          experimentId: routeExperimentId,
          pipelineStatus: currentStatusScreen.status,
          pipelineType: currentStatusScreen.type,
          pipelineErrorMessage: currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.message,
          completedSteps: currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.completedSteps,
          experimentName: experimentName
        });
      }
      if ((currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.type) === 'qc') {
        return __jsx(PipelineRedirectToDataProcessing, {
          experimentId: routeExperimentId,
          pipelineStatus: currentStatusScreen.status
        });
      }
      if (seuratComplete && currentModule === modules.DATA_PROCESSING) {
        navigateTo(modules.DATA_EXPLORATION, {
          experimentId: routeExperimentId
        });
        return __jsx(React.Fragment, null);
      }
      if (process.env.NODE_ENV === 'development') {
        return children;
      }
    }
    return children;
  };
  var getTertiaryModuleDisabled = function getTertiaryModuleDisabled(module) {
    var disableIfNoExperiment = false;
    var disabledByPipelineStatus = false;
    var disabledIfSeuratComplete = false;
    switch (module) {
      case modules.DATA_PROCESSING:
        disableIfNoExperiment = true;
        disabledIfSeuratComplete = true;
        break;
      case modules.DATA_EXPLORATION:
      case modules.PLOTS_AND_TABLES:
        disableIfNoExperiment = true;
        disabledByPipelineStatus = true;
        break;
      default:
        break;
    }
    var needRerunPipeline = pipelinesRerunStatus === null || pipelinesRerunStatus.rerun;
    var notProcessedExperimentDisable = !routeExperimentId && disableIfNoExperiment && needRerunPipeline;
    var pipelineStatusDisable = disabledByPipelineStatus && (backendError || gem2sRunning || gem2sRunningError || waitingForQcToLaunch || qcRunning || qcRunningError || seuratRunning || seuratRunningError);
    var DATA_EXPLORATION = modules.DATA_EXPLORATION,
      DATA_MANAGEMENT = modules.DATA_MANAGEMENT,
      DATA_PROCESSING = modules.DATA_PROCESSING,
      PLOTS_AND_TABLES = modules.PLOTS_AND_TABLES;
    var nonExperimentModule = ![DATA_EXPLORATION, DATA_MANAGEMENT, DATA_PROCESSING, PLOTS_AND_TABLES].includes(currentModule) && disableIfNoExperiment;
    var seuratCompleteDisable = disabledIfSeuratComplete && isSeurat;
    return notProcessedExperimentDisable || pipelineStatusDisable || seuratCompleteDisable || nonExperimentModule;
  };
  var menuItemRender = function menuItemRender(_ref4) {
    var module = _ref4.module,
      items = _ref4.items,
      icon = _ref4.icon,
      name = _ref4.name,
      selectedProjectText = _ref4.selectedProjectText,
      isDisabled = _ref4.isDisabled;
    var _onClick = function onClick(targetModule) {
      navigateTo(targetModule, {
        experimentId: currentExperimentId,
        secondaryAnalysisId: currentAnalysisIdRef.current
      });
    };
    return __jsx(SubMenu, {
      key: module,
      title: name,
      icon: icon,
      disabled: isDisabled,
      onTitleClick: function onTitleClick() {
        return _onClick(module);
      }
    }, __jsx(ItemGroup, {
      key: "active project",
      title: __jsx(Text, {
        style: {
          width: '100%',
          color: 'grey'
        },
        ellipsis: true
      }, selectedProjectText)
    }, items.map(function (item) {
      return __jsx(Item, {
        key: item.module,
        disabled: item.isDisabled,
        icon: item.icon,
        onClick: function onClick() {
          _onClick(item.module);
        }
      }, item.name);
    })));
  };
  if (!user) return __jsx(React.Fragment, null);
  var menuItems = menuLinks.map(menuItemRender);
  var isUserInModule = function isUserInModule(module, items) {
    return currentModule === module || items.some(function (item) {
      return item.module === currentModule;
    });
  };
  return __jsx(React.Fragment, null, __jsx(DndProvider, {
    backend: MultiBackend,
    options: HTML5ToTouch
  }, termsOfUseNotAccepted(user, domainName) && __jsx(TermsOfUseIntercept, {
    user: user
  }), __jsx(BrowserAlert, null), __jsx(_Layout, {
    style: {
      minHeight: '100vh'
    }
  }, __jsx(Sider, {
    style: {
      background: brandColors.BLACK_INDIGO,
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0
    },
    width: 210,
    theme: "dark",
    mode: "inline",
    collapsible: true,
    collapsed: collapsed,
    onCollapse: function onCollapse(collapse) {
      return setCollapsed(collapse);
    }
  }, __jsx("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  }, collapsed ? __jsx(SmallLogo, null) : __jsx(BigLogo, null), __jsx(_Menu, {
    style: {
      background: brandColors.BLACK_INDIGO
    },
    "data-test-id": integrationTestConstants.ids.NAVIGATION_MENU,
    theme: "dark",
    selectedKeys: [currentModule],
    mode: "inline",
    openKeys: collapsed ? undefined : menuLinks.filter(function (item) {
      return isUserInModule(item.module, item.items);
    }).map(function (item) {
      return item.module;
    })
  }, menuItems), __jsx("div", {
    style: {
      marginTop: 'auto',
      marginBottom: '0.5em',
      textAlign: collapsed ? 'center' : 'left'
    }
  }, __jsx(FeedbackButton, {
    buttonType: "text",
    collapsed: collapsed
  }), __jsx(ReferralButton, {
    collapsed: collapsed
  }), __jsx(_Divider, {
    style: {
      backgroundColor: 'hsla(0, 0%, 100%, .65)',
      height: '0.5px'
    }
  }), __jsx("div", {
    style: {
      margin: '0.5em 0',
      textAlign: 'center'
    }
  }, __jsx(UserButton, null), __jsx("br", null), __jsx("br", null), __jsx("span", {
    style: {
      fontSize: '0.75em',
      color: 'hsla(0, 0%, 100%, 0.65)'
    }
  }, "\xA9", ' ', __jsx("a", {
    href: "https://parsebiosciences.com/",
    style: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }, "Parse Biosciences"), ' ', "2020-2025.", __jsx("br", null), "All rights reserved."))))), __jsx(CookieBanner, null), __jsx(_Layout, {
    style: !collapsed ? {
      marginLeft: '210px'
    } : {
      marginLeft: '80px'
    } // this is the collapsed width for our sider
  }, renderContent()))));
};
ContentWrapper.defaultProps = {
  routeExperimentId: null,
  routeAnalysisId: null,
  experimentData: null,
  children: null
};
export default ContentWrapper;